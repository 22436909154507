@import "../../resources/scss/variables";

.retab__tabpanel {
    padding-left: 1rem;
}

.tabs {
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem 0 0;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 1rem;
}

.tabs > .unselectedTab {
  color: #007bff;
  font-size: $font-size-regular;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  margin-right: 3rem;
  border: 1px solid transparent;
  padding: 0.5rem 1rem 0.3rem 1rem;
  margin-bottom: -1rem;
}

.tabs > .selectedTab {
  color: #495057;
  font-size: $font-size-regular;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  margin-right: 3rem;
  border: 1px solid;
  border-color: #dee2e6 #dee2e6 #fff;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: -1px;
}