@import "../../../resources/scss/variables";

.buttons {
  margin-right: 0.7rem;
}

.customerChange {
  font-size: $font-size-regular;
  padding-bottom: 1.5rem;
  padding-left: 0.5rem;
  color: #007bff;
  &:hover{
    color: #005cbf;
    cursor: pointer;
  }
}

.newCustomer {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-style: solid;
  border-color: lightgray;
  border-width: 1px;
  padding: 0.5rem 1rem 0rem 1rem;
  border-radius: 0.5rem;
}