.accountType {
  padding: 0;
}

.lockoutInfo {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.lockoutBadge {
  margin-right: 0.75rem !important;
}

.lockoutBadgeLocked {
  margin-right: 0.75rem !important;
}