@import "../../../../../../resources/scss/variables";
.buttonList{
  margin: 1rem 0 2rem 0;
}

.channelButton {
  margin-right: 0.7rem;
}

.channelButtonSelected {
  margin-right: 0.7rem;
  background-color: #005cbf;
}

.submitButton {
  margin-left: 0.7rem;
}

.modalChannel {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.deleteHeader {
  padding-bottom: 0;
}

.checkBoxGroup {
  display: flex;
}

.checkLabel {
  padding-top: 1rem;
  margin-right: 1rem;
}