.mailerSummaryBox {
  display: flex;
  flex-direction: column;
}

.summaryValPair {
  display: flex;
  justify-content: left;
  margin-bottom: 1rem;
  margin-left: 0.3rem;
  margin-right: 0.5rem;
  width: 25rem;
}

.pairBadge {
  margin-top: 0.6rem;
  margin-left: 0.7rem;
}

.summaryFlexBox {
  display:flex;
  flex-direction: column;
}

.button {
  margin-top: 0.2rem;
}

.summaryRow {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
}