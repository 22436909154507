@import "/src/resources/scss/variables";

.header {
  font-size: $font-size-large !important;
}

.buttonContainer {
  display: flex;
  justify-content: right;
}

.submitButton {
  margin-right: 0.7rem;
}

.orText {
  text-align: center;
}

.formButtons {
  display: flex;
  justify-content: left;
}

.formRow {
  display: flex;
  justify-content: space-between;
}

.contactIcon {
  flex: 1;
  margin-right: 5rem;
}

.iconChanger {
  margin-top: auto;
  margin-bottom: auto;
  width: 200px;
}

.icon {
  font-size: 50px;
  margin-left: auto;
  margin-right: auto;
}

.contactLabel {
  flex: 9;
}

.userName {
  flex: 1;
  margin-right: 2.5rem;
}

.userEmail {
  flex: 1;
  margin-left: 2.5rem;
}