.boolField {
  width: 25%;
}

.buttons {
  margin-right: 0.7rem;
  margin-top: 0.5rem;
}

.editSummaryHeader {
  padding-bottom: 2rem;
}

.fieldsGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}