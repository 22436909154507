@import "src/resources/scss/variables";

.activeChannelsBox {
  flex-direction: row;
  margin-top: 1.5rem;
  justify-content: space-between;
  margin-right: 20rem;
}

.label {
  display: flex;
  font-weight: bold;
  align-content: flex-start;
}

.box {
  box-shadow: $box-shadow;
  padding: 1rem 2rem 2rem 2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.usersHeader {
  margin-bottom: -0.5rem;
}

.addButton {
  margin-right: 0.5rem;
}

.mybillsButton {
  margin-bottom: 2rem;
}

.buttonGroup {
  margin-top: 1rem;
  width: 45%;
}

.editButton {

}