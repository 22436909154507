@import "../../resources/scss/variables";

.listHeader {
  font-size: $font-size-large !important;
}

.listStyle {
  box-shadow: $box-shadow;
  padding: 1.5rem 2rem 1.5rem 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.eventBlock {
  margin-bottom: 1.6rem;
}

.event {
  font-size: $font-size-regular !important;
  display: flex;
  flex-direction: row;
}

.eventTime {
  margin-right: 0.5rem;
}

.hiddenButton {
  display: none;
}