@import "../../resources/scss/variables";


.infoRow {
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
  margin-bottom: 1rem;
}

.valPair {
  flex: 1;
}

.value {
  font-size: $font-size-regular !important;
}