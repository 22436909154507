@import "../../resources/scss/variables";

.hoverBoxShadow:hover, .hoverBoxShadow:focus {
  box-shadow: $box-shadow-hover
}

.boxShadow {
  margin-top: 3rem;
  margin-bottom: 2rem;
  box-shadow: $box-shadow;
  padding: 1rem 2rem 1rem 2rem;
}

.rows {
  margin-bottom: 0rem;
}

.rowStyle {
  display: flex;
  flex-direction: row;
  &:hover{
    cursor: pointer;
    background: whitesmoke;
  }
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}

.subText {
  font-size: 1.2rem;
  margin-bottom: 0;
  color: gray;
}

.requestId {
  margin-top: auto;
  margin-bottom: auto;
  justify-items: left;
  flex: 1;
}

.requestAction {
  margin-top: auto;
  margin-bottom: 0.25rem;
}

.actionName {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  flex: 6;
}

.requestReason {
  margin-top: auto;
  margin-bottom: auto;
  flex: 8;
}

.requestStatus {
  margin-top: auto;
  margin-bottom: 0.25rem;
  padding: 0.3rem;
}

.statusName {
  margin-top: auto;
  flex: 6;
}

.listPagination {
  margin-top: 0.5rem;
}