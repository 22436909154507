@import "../../../resources/scss/variables";

.listStyle {
  max-width: 90vw;
}
.noteBlock {
  display: flex;
  flex-direction: column;
  &:hover{
    cursor: pointer;
    background: whitesmoke;
  }
  padding: 1rem;
}

.noteBlock:hover>.noteRow>.rowButtons {
  cursor: pointer;
  display: flex;
  justify-content: right;
}

.noteRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rowPadding {
  padding-left: 1rem;
}

.labelStyle {
  margin-top: auto;
  margin-bottom: auto;
  font-size: $font-size-regular !important;
}

.buttonStyle {
  margin-top: 0.5rem;
  margin-bottom: auto;
}

.contactTitle {
  margin: 0.4rem 1rem 0.4rem 0;
}

.rowButtons {
  display: none;
  flex: 1;
}

.confirmRed {
  color: red !important;
}

.noteContent {
  flex: 9;
  margin: 0.7rem 0 0.7rem 0;
  font-size: $font-size-regular !important;

}

.subText {
  font-size: 1.2rem;
  margin-bottom: 0;
  color: gray;
}

.authorEditor {
  display: flex;
  justify-content: space-between;
}

.authorShared {
  display: flex;
}

.sharedSubtext * {
  font-size: 1.2rem !important;
}

.addNote {
  display: flex;
  justify-content: left;
  margin-top: 0.8rem;
}