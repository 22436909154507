@import "../../resources/scss/variables";

.userBox {
  box-shadow: $box-shadow;
  padding: 1rem 2rem 1rem 2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  min-width: 400px;
  overflow: auto;
}

