@import "../../resources/scss/variables";

.buttons {
  margin-bottom: -1.5rem;
}

.submitButton {
  margin-left: 0.7rem;
}

.box {
  box-shadow: $box-shadow;
  padding: 1rem 2rem 2rem 2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.editIntegrator {
  display: flex;
  justify-content: left;
  margin-top: 0.8rem;
}

.deleteIntegrator {
  display: flex;
  justify-content: left;
  margin-top: 0.8rem;
}