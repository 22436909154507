@import "../../../../../../resources/scss/variables";

.authMandatoryPair {
  display:flex;
  flex-direction: row;
}

.boolField {
  padding-top: 3rem;
  padding-left: 3rem;
  width: 20rem;
}

.loneBoolField {
  width: 26%;
}

.authField {
  width: 80%;
  margin-top: 0.5rem;
}

.loneAuthField {
  width: 97%;
  margin-top: 0.5rem;
}

.buttons {
  margin-right: 0.7rem;
}

.selectedButton {
  margin: 0.5rem 0.7rem 0rem 0rem;
  background-color: #063970;
  border-color: #063970;
}

.unselectedButton {
  margin: 0.5rem 0.7rem 0rem 0rem;
}

.box {
  box-shadow: $box-shadow;
  padding: 1rem 2rem 2rem 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.channelHeader {
  margin-bottom: 0.7rem;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.headerWarning {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.warning {
  margin: 1rem 0 0 0.5rem;
  padding-top: 0.5rem;
  font-weight: 500;
}

.fields {
  margin-left: 0.5rem;
}

.helpField {
  width: 97%;
  margin-bottom: 2rem;
}

.tabGroup {
  color: red;
}

.tab {
  font-size: $font-size-regular;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  margin-right: 3rem;
}

.authBlock {
  margin-top: 0.5rem;
  border-bottom: 1px solid rgba(0,0,0,.1);
  margin-bottom: 0.5rem;
}


