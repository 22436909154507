@import "../../../../resources/scss/variables";

.listStyle {
  max-width: 90vw;
}
.contactRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:hover{
    cursor: pointer;
    background: whitesmoke;
  }
  padding: 1rem;
}

.rowPadding {
  padding-left: 1rem;
}

.labelStyle {
  margin-top: auto;
  margin-bottom: auto;
  font-size: $font-size-regular !important;
}

.actionStyle {
}

.buttonStyle {
  margin-top: 0.5rem;
  margin-bottom: auto;
}

.contactTitle {
  margin: 0.4rem 1rem 0.4rem 0;
}

.rowButtons {
  display: none;
}

.contactRow:hover>.rowButtons{
  cursor: pointer;
  display: block;
}

.confirmRed {
  color: red !important;
}