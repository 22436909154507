
$font-size-small: 1rem;
$font-size-regular: 1.6rem;
$font-size-large: 2.4rem;

$spacing-small: 0.5rem;
$spacing-medium: 1rem;
$spacing-large: 2rem;

$box-shadow: 0 0.24em 0.8em 0 rgba(0, 0, 0, 0.25);
$box-shadow-hover: 0 0.24em 0.8em 0.4em rgba(0, 0, 0, 0.25);