
.nav {
  background: black;
  height: 7rem;
  width: 100%;
  position: absolute;
  top: 0;
  zIndex: 1;
}
.navComponent {
  color: white !important;
  margin-right: 0.5rem;
}

.dropDown {
  font-size: 1.6rem !important;

  a::after {
    content: none;
  }

  .nav-link {
    padding: 0;
  }
}

.navDrop {
  float: left !important;
  color: white !important;
  margin-bottom: 1rem;
}

.logo {
  width: 14rem;
  margin-right: 1rem;
  margin-top: auto;
  margin-bottom: auto;
}
