@import "../../../../resources/scss/variables";


.orText {
  text-align: center;
}

.editCustomerHeader {
  margin-bottom: 0.5rem;
  padding-bottom: 1rem;
}

.selectHeader {
  padding-bottom: 1rem;
}

.buttons {
  margin-top: 0.5rem;
  margin-right: 0.7rem;
}

.errorMessage {
  margin: -0.5rem 50rem 1rem 1rem;
  color: #D8000C;
  background-color: #FFBABA;
}

.list {
  max-width: 90vw;
  padding-bottom: 1rem;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:hover{
    cursor: pointer;
    background: whitesmoke;
  }
  padding: 0rem 1rem 1rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.rowPadding {
  padding-left: 1rem;
}

.label {
  margin-top: 1rem;
  margin-bottom: auto;
  font-size: $font-size-regular !important;
}

.button {
  margin-top: 0.5rem;
  margin-bottom: auto;
}

.example {
  padding: 0rem 0rem 0rem 1rem;
}

.searchBar {
  padding-top: 0.5rem;
}

.rowSelected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background: #Ced2d2;
  padding: 0rem 1rem 1rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.buttonSelected {
  color: #005cbf;
  &:hover{
    color: red;
  }
  margin-top: 0.5rem;
  margin-bottom: auto;
}