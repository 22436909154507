@import "../../../../resources/scss/variables";

.listStyle {
  max-width: 90vw;
}

.rowPadding {
  padding-left: 1rem;
}

.rowStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  &:hover{
    cursor: pointer;
    background: whitesmoke;
  }
  padding: 1rem;
}

.rowStyleRules {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  &:hover{
    cursor: pointer;
    background: whitesmoke;
  }
  padding: 1rem;
}

.labelStyle {
  margin-top: auto;
  margin-bottom: auto;
  font-size: $font-size-regular !important;
}

.box {
  box-shadow: $box-shadow;
  padding: 1rem 2rem 2rem 2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.boxHeading {
  margin-bottom: 1rem;
}

.addRule {
  display: flex;
  justify-content: left;
  margin-top: 0.8rem;
}