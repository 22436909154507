@import "../../resources/scss/variables";

.usersBox {
  border-radius: 10px;
  border: solid 1px #ccc;
  padding: 10px 20px 20px 20px;
  margin-top: 30px;
}

.usersHeader {
  margin-bottom: -5px;
}

.button {
  outline: red;

}

.actionDetails {
  margin-bottom: 20px;
}

.actionDetailsHeader {
  margin-top: 20px;
}

.userId {
  font-size: $font-size-regular !important;
}