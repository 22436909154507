@import "../../../../../../resources/scss/variables";

.loneAuthField {
  width: 97%;
  margin-top: 0.5rem;
}

.buttons {
  margin-right: 0.7rem;
}

.channelHeader {
  margin-bottom: 0.7rem;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.headerWarning {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.warning {
  margin: 1rem 0 0 0.5rem;
  padding-top: 0.5rem;
  font-weight: 500;
}

.authBlock {
  margin-top: 0.5rem;
  border-bottom: 1px solid rgba(0,0,0,.1);
  margin-bottom: 0.5rem;
}


