html {
  font-size: 62.5%; /*this is required to work in base 10px while still scaling to user's set browser font size*/
}

p {
  font-size: 1.6rem !important;
}

h1 {
  font-size: 3.6rem !important;
}

h2 {
  font-size: 3.2rem !important;
}

h3 {
  font-size: 3rem !important;
}

h4 {
  font-size: 2.8rem !important;
}

h5 {
  font-size: 2.6rem !important;
}

h6 {
  font-size: 2.4rem !important;
}

tr {
  font-size: 1.6rem !important;
}

td {
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

