@import "../../../resources/scss/variables";

.modal {
  border-radius: 0.48rem !important;
  margin-top: 1rem;
}

.modalPadding {
  padding: 1.5rem !important;
}

.header {
  font-size: $font-size-large !important;
}

.buttonContainer {
  display: flex;
  justify-content: right;
}

.submitButton {
  margin-left: 0.7rem;
}

.formButtons {
  display: flex;
  justify-content: left;
}

.formRow {
  display: flex;
  justify-content: space-between;
}

.contactIcon {
  flex: 3;
}

.iconChanger {
  margin-top: auto;
  margin-bottom: auto;
  width: 200px;
}

.icon {
  font-size: 50px;
}

.contactLabel {
  flex: 5;
  margin-right: 5rem;
}

.userName {
  flex: 1;
  margin-right: 2.5rem;
}

.userEmail {
  flex: 1;
  margin-left: 2.5rem;
}