.dropdownIcon {
  margin-top: 0.5rem;
  margin-bottom: auto;
}

.buttonCluster {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
  border-radius: 1rem;
  border: solid 1px #ccc;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
