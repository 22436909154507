.message {
  margin-bottom: 0.75rem;
}

.buttons {
  margin-bottom: -1.5rem;
}

.submitButton {
  margin-left: 0.7rem;
}