@import "../../resources/scss/variables";

.infoBoxBoxed {
  box-shadow: $box-shadow;
  padding: 1rem 2rem 2rem 2rem;
  margin-top: 2rem;
}

.infoBox {
  display: flex;
  flex-direction: column;
}

.infoBoxRow {
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
  margin-bottom: 1rem;
}

.hiddenRow {
  display: none;
}

.valPair {
  flex: 1;
}

.value {
  font-size: $font-size-regular !important;
}

.label {
  font-weight: bold;
}