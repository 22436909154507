.billFieldsBox {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.fields {
  margin-bottom: 1rem;
  display : flex;
  align-items: center;
}

.fieldLabel {
  margin-right: 2rem;
  width: 100px;
}

.fieldBox {
  margin-bottom: 0;
  flex-grow: 1;
}

.emptyText {
  padding-top: 1rem;
}