@import "../../resources/scss/variables";

.box {
  box-shadow: $box-shadow;
  padding: 1rem 2rem 2rem 2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.usersHeader {
  margin-bottom: -0.5rem;
}

.notesHeader {
  margin-bottom: 1rem;
}

.button {
  outline: red;

}

.dropdownNoArrow::after {
  content: none;
}

.inlineChange {
  display: flex;
  margin-bottom: -0.2rem;
}

.inlineIcon {
  padding: 0 !important;
  margin-left: 0.5rem;
  font-size: 2rem !important;
}

.inlineButton {
  padding: 0 !important;
}

.noBox {
  box-shadow: none !important;
  padding: 0 0 0 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}