@import "../../../../resources/scss/variables";

.buttons {
  margin-bottom: -1.5rem;
}
.submitButton {
  margin-left: 0.7rem;
}

.smallText {
  display: flex;
  flex-wrap: wrap;
  > * {
    flex: 1 1 50%;
  }

  :nth-child(3) {
    text-align: end;
  }
}

.preserveWhitespace {
  white-space: pre-wrap;
}