
.contacts {
  margin-top: 2rem;
}

.addContact {
  display: flex;
  justify-content: left;
  margin-top: 0.7rem;
}
