@import "../../resources/scss/variables";

.loginPage {
}
.logoText {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.loginHeader {
  text-align: center;
  padding-top: 0.5rem;
  margin-top: auto;
  margin-bottom: 2rem;
  font-size: 7.5rem !important;
}
.atlasLogo {
  max-height: 10rem;
}
.loginSubHeader {
  text-align: center;
  font-size: 2.8rem !important;
  margin-bottom: 2rem;
  color: gray;
}
.loginBox {
  box-shadow: $box-shadow;
  padding: 2rem;
  margin: auto;
  max-width: 45rem;
}
.boxHeader {
  text-align: center;
  margin-bottom: 2.4rem;
  font-size: 2.4rem !important;
  color: gray;
}

.loginButton {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}