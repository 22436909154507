@import "../../resources/scss/variables";

.formGroup {
  margin-bottom: 1.6rem;
}


.formEntry {
  font-size: $font-size-regular !important;
  padding: 1.8rem 1.2rem 1.8rem 1.2rem !important;
  border-radius: 0.5rem !important;
}

.label {
  font-size: $font-size-regular;
}

.errors {
  font-size: 1.28rem !important;
}