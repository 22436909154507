@import "../../resources/scss/variables";

.input {
  font-size: $font-size-regular !important;
}

.dropdownList {
  max-height: 19.5rem;
  overflow-y: auto;
  border-style: solid;
  border-color: lightgray;
  border-width: 1px;
}

.listElement {
  padding: 0.7rem 0 0.7rem 1.5rem;
  font-size: $font-size-regular !important;
  border-bottom-style: solid;
  border-color: lightgray;
  border-width: 1px;
  &:hover{
    cursor: pointer;
    background: whitesmoke;
  }
}

.errors {
  font-size: 1.28rem !important;
}