@import "../../resources/scss/variables";

.regoBox {
  box-shadow: $box-shadow;
  padding: 1rem 2rem 2rem 2rem;
  margin-top: 3rem;
  min-width: 400px;
  overflow: auto;
}

.regoHeader {
  margin-bottom: -0.5rem;
}

.accBox {
  box-shadow: $box-shadow;
  padding: 1rem 2rem 1rem 2rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  min-width: 400px;
  overflow: auto;
}

.accHeader {
  margin-bottom: 1rem;
}

.boldError {
  margin-top: 3rem;
}

.dropdownNoArrow::after {
  content: none;
}