.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.paginationMeta {
  margin-left: 1rem;
  margin-top: auto;
  margin-bottom: -0.3rem;
}

.paginationLoading {
  margin-left: 1rem;
  margin-top: auto;
  margin-bottom: 0.85rem;
}