.button {
  padding: 0.6rem 1.2rem 0.6rem 1.2rem !important;
}

.buttonContent {
  display: flex;
  flex-direction: row;
}

.icon {
  margin-top: auto;
  margin-right: 0.7rem;
}

.spinner {
  margin-left: 0.5rem;
  margin-top: auto;
  margin-bottom: auto;
}