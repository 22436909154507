@import "src/resources/scss/variables";

.Box {
  box-shadow: $box-shadow;
  padding: 1rem 2rem 2rem 2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.subFields {
  flex: 1;
  display: flex;
}

.keyVal {
  flex: 1;
}

.header {
  padding-top: 1rem;
}

.fieldLabel {
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.fieldText {
  padding-left: 1rem;
}

.subElement {
  margin-left: 1rem;
}

.rightDiv {
  padding-left: 2rem;
}

.subFieldsTable {
  flex: 1;
  display: flex;
  padding-top: 1rem;
  margin-right: 50%;
}

.titleDiv {
  display: flex;
}

.titleElement {
  padding-right: 0.8rem;
  margin-bottom: -1rem;
}

.secondaryHeading {
  margin-bottom: 1rem;
}

.nonconfigHeading {
  margin-bottom: 0rem;
}