.group {
  display: flex;
  flex-direction: row;
  padding: 0.3rem;
  margin-top: auto;
  margin-bottom: auto;
}

.text {
  margin-left: 0.7rem;
  margin-bottom: auto;
}