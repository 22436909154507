@import "../../../resources/scss/variables";

.formGroup {
  margin-bottom: 1.6rem;
  flex-direction: column;
  display: flex;
}

.formEntry {
  font-size: $font-size-regular !important;
  padding: 1.8rem 1.2rem 1.8rem 1.2rem !important;
}

.label {
  font-size: $font-size-regular;
  align-self: center !important;
}

.errors {
  font-size: 1.28rem !important;
}