.customerSummaryBox {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  justify-content: space-between;
  margin-right: 20rem;
}

.rowDiv {
  flex: 1;
  justify-content: left;
  padding-top: 1rem;
}

.fieldLabel {
  font-weight: bold;
  padding: 0.3rem 50px 0.1rem 0.3rem;
  margin-bottom: 0;
}

.headerDiv {
  display: flex;
  flex-direction: row;
}

.indDiv {
  margin-bottom: 1rem;
}

.buttonDiv {
  display: flex;
  flex-direction: row;
}

.allElemens {
  display: flex;
  flex-direction: column;
}