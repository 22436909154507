
.carousel {
  display: flex;
  flex-direction: row;
}

.icon {
  font-size: 6rem;
  padding: 0 2.5rem 0 2.5rem;
  margin-left: auto;
  margin-right: auto;
}

.navControl {
  color: black;
}