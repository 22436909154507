@import "../../../../../resources/scss/variables";

.authFieldsBox {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-right: 20rem;
}

.fields {
  margin-bottom: 0.3rem;
  margin-left: 0.3rem;
  display : flex;
}

.fieldText {
  margin-bottom: 0;
  padding: 0.3rem 0 0.3rem 0.3rem;
  display: flex;
  margin-left: 0.3rem;
  font-size: $font-size-regular;

}
.fieldLabel {
  padding: 0.3rem 0 0.3rem 0;
  margin-left: 0.7rem;
  font-size: $font-size-regular;
}

.fieldHeader {
  font-weight: bold;
  padding-right: 0;
}

.fieldHeaderOp {
  padding-left: 0;
  font-weight: bold;
}

.contentDiv {
  display: flex;
  flex-direction: column;
}

.headerDiv {
  display: flex;
}

.box {
  box-shadow: $box-shadow;
  padding: 1rem 2rem 2rem 2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
}