@import "../../../resources/scss/variables";

.formGroup {
  margin-bottom: 1.6rem;
  padding-top: 0.1rem;
  flex-direction: row;
  display: flex;
  justify-content: normal;
  align-items: center;
}

.formEntry {
  font-size: small !important;
  padding: 1.8rem 1.2rem 1.8rem 1.2rem !important;
  margin-top: 0.1rem;
  height: 1.3rem !important;
  width: 4%;
}

.label {
  font-size: $font-size-regular;
  margin-top: 0.4rem;
}

.errors {
  font-size: 1.28rem !important;
}