@import "../../resources/scss/variables";

.listStyle {
  max-width: 90vw;
}
.rowStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:hover{
    cursor: pointer;
    background: whitesmoke;
  }
  padding: 1rem;
}

.rowPadding {
  padding-left: 1rem;
}

.labelStyle {
  margin-top: auto;
  margin-bottom: auto;
  font-size: $font-size-regular !important;
}

.actionStyle {
}

.buttonStyle {
  margin-top: 0.5rem;
  margin-bottom: auto;
}